

// @ts-nocheck

import Vue from 'vue';

import {
    mapGetters,
    mapActions,
    mapMutations,
    mapState,
} from 'vuex';

import UserEdit from './UserEdit.vue';
import LoginAsLink from './UserLinkAs.vue';
import DonationTable from '../donation/DonationTable.vue';
import UserEditSubscribe from './UserEditSubscribe.vue';
import UserLogsTable from '../log/UserLogsTable.vue';

import { usersActions, usersGetters, usersMutations } from '@/store/modules/users/names';
import { generalActions, generalMutations } from '@/store/modules/general/names';
import { ComposedState } from '@/types';

const pendingLoaderSet = {
    state: true,
    preloader: true,
    icon: false,
};

const finishPendingLoaderSet = {
    state: true,
    preloader: false,
    icon: true,
};

const defaultLoaderSet = {
    state: false,
    preloader: false,
    icon: true,
};

export default Vue.extend({
    name: 'UserItem',

    components: {
        UserEdit,
        LoginAsLink,
        DonationTable,
        UserEditSubscribe,
        UserLogsTable,
    },

    data() {
        return {
            ordersDialog: false,
            ordersData: {},
            paymentsModal: false,
            paymentData: {},
            isVipStatus: null,
            tickedNumber: '',
            applicationName: '',
            editEmailDialog: false,
            changeEmailModel: '',
            disabledBtnSaveNewEmail: true,
            loaderSetUpdateUser: { ...defaultLoaderSet },
            emailRules: [
                (v) => {
                    if (v) {
                        this.disabledBtnSaveNewEmail = false;
                        return true;
                    } 
                    this.disabledBtnSaveNewEmail = true;
                    return 'E-mail is required';
                },
                (v) => {
                    if (/.+@.+\..+/.test(v)) {
                        this.disabledBtnSaveNewEmail = false;
                        return true;
                    } 
                    this.disabledBtnSaveNewEmail = true;
                    return 'E-mail must be valid';
                },
            ],

            blockUserModal: false,
            deleteUserModal: false,
            blockUserComment: '',            
            deleteUserComment: '',
            isPopupVisible: false,
            popupTitle: '',
            popupText: '',
            currentUserStatus: '',
        };
    },

    computed: {
        ...mapGetters({
            commentRules: usersGetters.commentRules,
        }),

        ...mapState<ComposedState>({
            admin: (state: ComposedState) => state.admin.admin,
            user: (state: ComposedState) => state.users.user,
            loadedStatus: (state: ComposedState) => state.users.loadedStatus,
            errors: (state: ComposedState) => state.general.errors,
            countries: (state: ComposedState) => state.general.countries,
        }),

        userId() {
            return this.$route.params.id;
        },
        
        editEmailError(): string[] {
            return this.errors.find((err) => err.includes('already in use'));
        },

        userCountry() {
            return this.countries.find((country) => country.id === this.user.country_id );
        },

        isDisabledBlockSaveBtn() {
            if (!this.user.is_active) {
                return false;
            }

            return !(this.blockUserComment.length > 3 && this.blockUserComment.length < 301);
        },

        isDisabledDeleteSaveBtn() {
            return !(this.deleteUserComment.length > 3 && this.deleteUserComment.length < 301);
        },
    },

    watch: {
        async userId() {
            await this.fetchUser(this.userId);
            this.isVipStatus = this.user.is_vip;                
        },

        editEmailError(error) {
            if (error) {
                this.loaderSetUpdateUser = { ...defaultLoaderSet };
            }
        },

        user() {
            if (this.user) {
                this.currentUserStatus = this.user.is_active ? 'Unblock' : 'Block';

                if (this.user.is_delete) {
                    this.currentUserStatus = 'Delete';
                }
            } else {
                this.currentUserStatus = '';
            }
        },
    },

    async mounted() {
        await this.getCountries();
        const response = await this.fetchUser(this.userId);
        this.isVipStatus = this.user.is_vip;
        this.updateDefaultCountry(response.data.phone_info.iso2);

        if (localStorage.getItem('showPopupAfterReload')) {         
            this.popupTitle = 'Success!';
            this.popupText = `
                User ${this.user.surname} 
                ${this.user.name} ${this.user.verified_email}
                has been transferred to the 
                “${this.currentUserStatus}” status.
            `;

            this.isPopupVisible = true;
            localStorage.removeItem('showPopupAfterReload');
        }
    },

    methods: {
        ...mapActions({
            fetchUser: usersActions.fetchUser,
            editUser: usersActions.editUser,
            blockUser: usersActions.blockUser,
            unblockUser: usersActions.unblockUser,
            deleteUser: usersActions.deleteUser,
            setVipStatusApi: usersActions.setVipStatus,
            getCountries: generalActions.getCountries,
        }),

        ...mapMutations({
            updateDefaultCountry: usersMutations.UPDATE_DEFAULT_COUNTRY,
            clearEmailError: generalMutations.RESET_ERRORS,
        }),

        changeVipStatus() {
            this.setVipStatusApi({ user_id: this.userId, is_vip: !this.user.is_vip });
            this.isVipStatus = !this.user.is_vip;
        },

        async changeEmail() {
            this.loaderSetUpdateUser = { ...pendingLoaderSet };

            await this.editUser({
                id: this.userId,
                email: this.changeEmailModel,
            });

            this.loaderSetUpdateUser = { ...finishPendingLoaderSet };
            this.clearEmailError();

            setTimeout(() => {
                this.editEmailDialog = false;
                this.loaderSetUpdateUser = { ...defaultLoaderSet };
            }, 800);
        },

        async switchBlockUser() {
            this.loaderSetUpdateUser = { ...pendingLoaderSet };

            if (this.user.is_active) {
                const res = await this.blockUser({
                    user_id: +this.userId,
                    comment: this.blockUserComment,
                });         
            } else {
                const res = await this.unblockUser({
                    user_id: +this.userId,
                });
            }

            this.loaderSetUpdateUser = { ...finishPendingLoaderSet };

            this.handleResponse(res, 'block');
        },

        async switchDeleteUser() {
            this.loaderSetUpdateUser = { ...pendingLoaderSet };

            const res = await this.deleteUser({
                user_id: +this.userId,
                comment: this.deleteUserComment,
            });

            this.loaderSetUpdateUser = { ...finishPendingLoaderSet };

            this.handleResponse(res, 'delete');
        },

        async handleResponse(res, action) {
            this.loaderSetUpdateUser = { ...finishPendingLoaderSet };

            setTimeout(() => {
                this.blockUserModal = false;
                this.deleteUserModal = false;

                this.loaderSetUpdateUser = { ...defaultLoaderSet };

                if (res && res.status === 200) {
                    localStorage.setItem('showPopupAfterReload', 'true');
                    location.reload();
                } else {
                    this.popupTitle = 'Error!';
                    this.popupText = `
                        The user has not been 
                        promoted to a new status. 
                        Please try again later 
                        or contact support.
                    `;
                    this.isPopupVisible = true;
                }
            }, 800);
        },

        openPayments(data) {
            this.paymentData = data;
            this.paymentsModal = true;
        },

        openOrders(data) {
            this.ordersData = data;
            this.ordersDialog = true;
        },

        async callbackChangeUser() {
            await this.fetchUser(this.userId);
        },

        writeToUser() {
            this.$router.push({ 
                path: '/support-chat', 
                query: { id: +this.userId },
            });
        },
    },
});
