
import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { logsActions } from '@/store/modules/log/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'DonationTable',

    props: { 
        userId: {
            type: String,
            default: null,
        }, 
    },

    data() {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const lastYear = new Date(today);
        lastYear.setFullYear(today.getFullYear() - 1);
        const lastYearYyyy = lastYear.getFullYear();
        const lastYearMm = String(lastYear.getMonth() + 1).padStart(2, '0');
        const lastYearDd = String(lastYear.getDate()).padStart(2, '0');
        return {
            sort: {
                sortDesc: [],
                sortBys: [],
            },
            dialog: false,
            dates: [] as string[],
            dateError: false,
            errorMessage: '',
            sortObj: {},
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            logsOptions: {
                created_at_from: '',
                created_at_to: '',
                search: '',
                page: 1,
                size: 10,
            },
            loading: false,
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },
            headers: [
                { text: 'Date', value: 'created_at', align: 'center' },
                { text: 'Type', value: 'action', align: 'center' },
                { text: 'Author', value: 'author', align: 'center' },
                { text: 'Recording', value: 'action_text', align: 'center' },
            ],
            today: `${yyyy}-${mm}-${dd}`,
            lastYear: `${lastYearYyyy}-${lastYearMm}-${lastYearDd}`,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            logs: (state: ComposedState) => state.logs.logs,
            total: (state: ComposedState) => state.logs.total,
        }),

        dateRangeText: {
            get(): string {
                return this.dates.join(' ~ ');
            },
            set(value: string) {
                const dates = value.split('~').map((date) => date.trim());
                if (
                    dates.length === 2 
                    && this.isValidDate(dates[0]) 
                    && this.isValidDate(dates[1])
                ) {
                    this.dates = dates;
                    if (new Date(dates[0]) > new Date(dates[1])) {
                        this.dateError = true;
                        this.errorMessage = 'The "from" date cannot be later than the "to" date.';
                    } else {
                        this.dateError = false;
                        this.errorMessage = '';
                    }
                } else {
                    this.dateError = true;
                    this.errorMessage = 'Invalid date format. Please use YYYY-MM-DD ~ YYYY-MM-DD.';
                }
            },
        },

        logArray(): any[] {
            if (this.userId) {
                // @ts-ignore
                return this.logs;
            }

            return [];
        },

        count(): number {
            // @ts-ignore
            return this.total;
        },
    },

    watch: {
        options: {
            deep: true,
            handler() {
                this.logsOptions.page = this.options.page;
                this.logsOptions.size = this.options.itemsPerPage;
            },
        },

        sort: {
            deep: true,
            handler() {
                this.reduceArr();
            },
        },

        sortObj: {
            deep: true,
            handler() {
                this.getLogsApi({ ...this.logsOptions, ...this.sortObj });
            },
        },

        logsOptions: {
            deep: true,
            handler() {
                this.getLogsApi({ ...this.logsOptions, ...this.sortObj });
            },
        },

        dateRangeText(newVal: string) {
            const dates = newVal.split('~').map((date) => date.trim());
            if (dates.length === 2 && this.isValidDate(dates[0]) && this.isValidDate(dates[1])) {
                this.dates = dates;
            }
        },
    },

    async mounted() {
        if (!this.logsOptions.created_at_from) {
            this.logsOptions.created_at_from = `${this.lastYear}T00:00:00.000000`;
            this.logsOptions.created_at_to = `${this.today}T23:59:59.000000`;
        }
        await this.getLogsApi({ ...this.logsOptions, ...this.sortObj });
    },

    methods: {
        ...mapActions({
            getLogs: logsActions.getLogs,
        }),

        validateInput(event: KeyboardEvent) {
            const allowedKeys = /[0-9\-~]/;
            const { key } = event;
            if (!allowedKeys.test(key)) {
                event.preventDefault();
            }
        },

        isValidDate(date: string): boolean {
            if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
                return false;
            }
            
            const [year, month, day] = date.split('-').map(Number);
            const dateObj = new Date(year, month - 1, day);
            
            return dateObj.getFullYear() === year 
                && dateObj.getMonth() === month - 1
                && dateObj.getDate() === day;
        },

        async reduceArr() {
            await this.$nextTick();
            const arrAscDesc = this.sort.sortDesc.map((item) => {
                if (item === false) {
                    return 'asc';
                }
                return 'desc';
            });

            const reducedSortObj = this.sort.sortBys.reduce((acc, n, i) => ({
                ...acc,
                [n]: arrAscDesc[i],
            }), {});
            
            this.sortObj = {
                ...reducedSortObj,
            };

            this.getLogsApi({ ...this.logsOptions, ...this.sortObj });
        },

        async getLogsApi(params) {
            if (this.userId) {
                await this.getLogs({ ...params, ['user_id']: this.userId });
                return;                
            }
            
            await this.getLogs(params);
        },

        dateClick() {
            this.dialog = false;
            this.logsOptions.created_at_from = this.dates.length > 0
                ? `${this.dates[0]}T00:00:00.000000` 
                : `${this.lastYear}T00:00:00.000000`;

            this.logsOptions.created_at_to = this.dates.length === 2 
                ? `${this.dates[1]}T23:59:59.000000`
                : `${this.today}T23:59:59.000000`;

            if (
                new Date(this.logsOptions.created_at_from) 
                > new Date(this.logsOptions.created_at_to)
            ) {
                this.dateError = true;
                this.errorMessage = 'The "from" date cannot be later than the "to" date.';
                return;
            }

            this.getLogsApi({ ...this.logsOptions, ...this.sortObj });
        },
    },
});
